import * as React from "react"
import {  Tabs, Tab } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ComponentKit from "../hooks/kit";
import ComponentInnovacion from "../hooks/innovacion";
import ComponentCompetencias from "../hooks/competencias";
import ComponentRecursos from "../hooks/recursos";

export default function EduToolKit() {
    return (
        <Layout pageInfo={{ pageName: "Edutoolkit" }}>
            <SEO title="Edutoolkit" />
            <br />
            <br />
            <br></br>
            <Tabs defaultActiveKey="herramientas" id="uncontrolled-tab-example" className="mb-3 nav-fill integrantes-tab">

                <Tab eventKey="herramientas" style={{textAlign: `justify`}} className="pt-3" title="Kit Herramientas">
                    <ComponentKit></ComponentKit>
                </Tab>
                <Tab eventKey="innovacion" style={{textAlign: `justify`}} className="pt-3" title="Innovación educativa">
                    <ComponentInnovacion></ComponentInnovacion>
                </Tab>
                <Tab eventKey="competencias" style={{textAlign: `justify`}}  className="pt-3"title="Competencia">
                    <ComponentCompetencias></ComponentCompetencias>
                </Tab>
                <Tab eventKey="recursos" style={{textAlign: `justify`}} title="Recursos educativos">
                    <ComponentRecursos></ComponentRecursos>
                </Tab>
            </Tabs>
        </Layout>
    )
}